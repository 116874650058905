<template>
  <el-color-picker
    :class="desc.class"
    :style="desc.style"
    v-bind="attrs"
    v-model="newValue"
    v-on="onEvents"
  />
</template>

<script>
import formMixin from '../mixins/formMixin'
export default {
  name: 'EleFormColor',
  mixins: [formMixin],
  data () {
    return {
      type: 'String',
      mockRule: '@color'
    }
  }
}
</script>
