var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-tabs',_vm._g(_vm._b({model:{value:(_vm.currentGroupId),callback:function ($$v) {_vm.currentGroupId=$$v},expression:"currentGroupId"}},'el-tabs',_vm.attrs,false),_vm.tabOn),[_vm._l((_vm.computedGroups),function(item){return [(_vm.getVif(item))?_c('el-tab-pane',{key:item.groupId,attrs:{"label":item.groupLabel,"name":item.groupId}},[(item.groupId === _vm.currentGroupId)?_c('ele-form',_vm._g(_vm._b({ref:"ele-form",refInFor:true,scopedSlots:_vm._u([_vm._l((item.form.formDesc),function(formItem,key,index){return {key:key,fn:function(ref){
var desc = ref.desc;
var props = ref.props;
var field = ref.field;
var formData = ref.formData;
return [_vm._t(item.groupId + '-' + field,[_c(desc._type,{key:index,ref:field,refInFor:true,tag:"component",attrs:{"disabled":desc._disabled,"desc":desc,"options":desc._options,"formData":formData,"readonly":props.readonly,"field":field,"value":_vm.getValue(field),"_disabled":desc._disabled},on:{"input":function($event){return _vm.setValue(field, $event)}}})],{"data":formData[field],"desc":desc,"props":props,"field":field,"formData":formData,"disabled":desc._disabled,"type":desc._type,"options":desc._options})]}}}),{key:"form-btn",fn:function(ref){
var btns = ref.btns;
return [_vm._t(item.groupId + '-form-btn',null,{"btns":btns})]}}],null,true)},'ele-form',item.form,false),item.on)):_vm._e()],1):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }